import { adminApi } from "../_axios";

interface UserParams {
  teachers?: string[];
  schoolYears?: string[];
  educationTypes?: string[];
  cities?: string[];
  name?: string;
  [key: string]: any;
}

const buildQueryString = (params: UserParams): string => {
  const param = Object.entries(params)
    .filter(([_, value]) => value != null && value !== "")
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${encodeURIComponent(key)}=${value
          .map(encodeURIComponent)
          .join(",")}`;
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");
  console.log(params);
  console.log(param);
  return param;
};

const getProfile = () => adminApi.get("/profile");
const updateProfile = (data: any) => adminApi.post("/profile", data);
const changePassword = (data: any) =>
  adminApi.post("/profile-change-password", data);

const adminLogin = (data: any) => adminApi.post("/login", data);
const getHome = ({
  queryName,
  queryValue,
}: {
  queryName?: string;
  queryValue?: string;
}) => adminApi.get(`/home?${queryName}=${queryValue}`);
const getPermissions = () => adminApi.get("/permissions");
const getMyPermissions = () => adminApi.get("/my-permissions");
const getSubjects = () => adminApi.get("/subjects");
const getSingleSubject = (id: number) => adminApi.get(`/subjects/${id}`);
const createSubject = (data: any) => adminApi.post("/subjects", data);
const updateSubject = (id: number, data: any) =>
  adminApi.post(`/subjects/${id}`, data);
const deleteSubject = (id: number) => adminApi.delete(`/subjects/${id}`);

const getTeachers = () => adminApi.get("/teachers");
const getSingleTeacher = (id: number) => adminApi.get(`/teachers/${id}`);
const createTeacher = (data: any) => adminApi.post("/teachers", data);
const updateTeacher = (id: number, data: any) =>
  adminApi.post(`/teachers/${id}`, data);
const deleteTeacher = (id: number) => adminApi.delete(`/teachers/${id}`);
const updateTeachersOrder = (data: any) =>
  adminApi.post(`/order-teachers/`, { teachers: data });

const getBooks = () => adminApi.get("/books");
const getSingleBook = (id: number) => adminApi.get(`/books/${id}`);
const createBook = (data: any) => adminApi.post("/books", data);
const updateBook = (id: number, data: any) =>
  adminApi.post(`/books/${id}`, data);
const deleteBook = (id: number) => adminApi.delete(`/books/${id}`);

const getExternalCodes = () => adminApi.get("/external-codes");
const getExternalCode = (id: number) => adminApi.get(`/external-codes/${id}`);
const createExternalCode = (data: any) =>
  adminApi.post("/external-codes", data);
const updateExternalCode = (id: number, data: any) =>
  adminApi.post(`/external-codes/${id}`, data);
const deleteExternalCode = (id: number) =>
  adminApi.delete(`/external-codes/${id}`);

const getInternalCodes = () => adminApi.get("/internal-codes");
const getInternalCode = (id: number) => adminApi.get(`/internal-codes/${id}`);
const createInternalCode = (data: any) =>
  adminApi.post("/internal-codes", data);
const updateInternalCode = (id: number, data: any) =>
  adminApi.post(`/internal-codes/${id}`, data);
const deleteInternalCode = (id: number) =>
  adminApi.delete(`/internal-codes/${id}`);

const getModerators = () => adminApi.get("/moderators");
const getSingleModerator = (id: number) => adminApi.get(`/moderators/${id}`);
const createModerator = (data: any) => adminApi.post("/moderators", data);
const updateModerator = (id: number, data: any) =>
  adminApi.post(`/moderators/${id}`, data);
const deleteModerator = (id: number) => adminApi.delete(`/moderators/${id}`);

const getStudents = (page: number, params: UserParams = {}) => {
  const queryString = buildQueryString(params);
  const pageParam = page ? `page=${page}` : "";
  const separator = pageParam && queryString ? "&" : "";

  return adminApi.get(`/students?${pageParam}${separator}${queryString}`);
};

const getSingleStudent = (id: number) => adminApi.get(`/students/${id}`);
const getStudentExams = (
  studentId: number,
  courseId: number,
  isFullExam?: boolean
) => {
  if (isFullExam) {
    return adminApi.get(`/students/${studentId}/full-exams/${courseId}`);
  } else {
    return adminApi.get(`/students/${studentId}/courses/${courseId}/exams`);
  }
};
const correctQuestion = (id: number, data: any, isFullExam?: boolean) => {
  if (isFullExam) {
    return adminApi.post(`/correct-full-exam-questions/${id}`, data);
  } else {
    return adminApi.post(`/correct-questions/${id}`, data);
  }
};

const getUsers = (page: number, params: UserParams = {}) => {
  const queryString = buildQueryString(params);
  const pageParam = page ? `page=${page}` : "";
  const separator = pageParam && queryString ? "&" : "";

  return adminApi.get(`/users?${pageParam}${separator}${queryString}`);
};

const getSingleUser = (id: number) => adminApi.get(`/users/${id}`);
const createUser = (data: any) => adminApi.post("/users", data);
const updateUserStatus = (id: number, data: any) =>
  adminApi.post(`/update-user-status/${id}`, data);
const updateUser = (id: number, data: any) =>
  adminApi.post(`/users/${id}`, data);
const deleteUser = (id: number) => adminApi.delete(`/users/${id}`);

const getAllVideos = (
  teacherId: number | null,
  page: number | null,
  limit: number | null
) =>
  adminApi.get(
    `/videos?${teacherId ? `teacher_id=${teacherId}` : ""}&${
      page ? `page=${page}&` : ""
    }&${limit ? `page=${limit}&` : ""}&`
  );
const getTeacherVideos = (teacherId: number) =>
  adminApi.get(`/teacher-videos/${teacherId}`);
const getSingleVideo = (id: number) => adminApi.get(`/videos/${id}`);
const createVideo = (data: any) => adminApi.post("/videos", data);
const updateVideo = (id: number, data: any) =>
  adminApi.post(`/videos/${id}`, data);
const deleteVideo = (id: number) => adminApi.delete(`/videos/${id}`);
// courses
const getCourses = () => adminApi.get("/courses");
const getSingleCourse = (id: number) => adminApi.get(`/courses/${id}`);
const createCourse = (data: any) => adminApi.post("/courses", data);
const updateCourse = (id: number, data: any) =>
  adminApi.post(`/courses/${id}`, data);
const deleteCourse = (id: number) => adminApi.delete(`/courses/${id}`);
// courses - chapter
const createCourseChapter = (id: number, data: any) =>
  adminApi.post(`/courses/${id}/chapters`, data);
const updateCourseChapter = (courseId: number, chapterId: number, data: any) =>
  adminApi.post(`/courses/${courseId}/chapters/${chapterId}`, data);
const deleteCourseChapter = (courseId: number, chapterId: number) =>
  adminApi.delete(`/courses/${courseId}/chapters/${chapterId}`);

// courses - chapter - lessons
const createCourseLesson = (id: number, data: any) =>
  adminApi.post(`/chapters/${id}/lessons`, data);
const deleteCourseLesson = (chapterId: number, lessonId: number) =>
  adminApi.delete(`/chapters/${chapterId}/lessons/${lessonId}`);
const updateLesson = (chapterId: number, lessonId: number, data: any) =>
  adminApi.post(`/chapters/${chapterId}/lessons/${lessonId}`, data);
const updateLessonsOrder = (chapterId: number, data: any) =>
  adminApi.post(`/chapters/${chapterId}/order-lessons/`, data);
const getTeacherChapters = (id: any) =>
  adminApi.get(`/get-teacher-chapters/${id}`);
const duplicateChapter = (data: any) =>
  adminApi.post(`/duplicate-chapter`, data);

// full exam
const getFullExams = () => adminApi.get(`/full-exams`);
const getSingleFullExam = (id: number) => adminApi.get(`/full-exams/${id}`);
const createFullExam = (data: any) => adminApi.post("/full-exams", data);
const updateFullExam = (id: number, data: any) =>
  adminApi.post(`/full-exams/${id}`, data);
const deleteFullExam = (id: number) => adminApi.delete(`/full-exams/${id}`);

const createFullExamQuestion = (id: number, data: any) =>
  adminApi.post(`/full-exams/${id}/questions`, data);
const deleteFullExamQuestion = (id: number, questionId: number) =>
  adminApi.delete(`/full-exams/${id}/questions/${questionId}`);
const updateFullExamQuestionsOrder = (chapterId: number, data: any) =>
  adminApi.post(`/full-exams/${chapterId}/order-questions/`, data);

const getSingleFullExamQuestion = (id: number, idQuestion: number) =>
  adminApi.get(`/full-exams/${id}/questions/${idQuestion}`);
const updateFullExamQuestion = (id: number, idQuestion: number, data: any) =>
  adminApi.post(`/full-exams/${id}/questions/${idQuestion}`, data);

// Money
const getMoneyRequests = () => adminApi.get("/money-requests");
const getMoneyRequest = (id: number) => adminApi.get(`/money-requests/${id}`);
const createMoneyRequest = (data: any) =>
  adminApi.post("/money-requests", data);
const updateMoneyRequest = (id: number, data: any) =>
  adminApi.post(`/money-requests/${id}`, data);

// Finances
const getFinances = () => adminApi.get("/finances");
const getSingleBookSalesInfo = (id: number) =>
  adminApi.get(`/books/${id}/sales-info`);
const getSingleUserChargeWalletInfo = (id: number) =>
  adminApi.get(`/users/${id}/charge-wallet-info`);
const getSingleTeacherFinanceInfo = (id: number) =>
  adminApi.get(`/teachers/${id}/sales-info`);
const getSingleCourseFinanceInfo = (id: number) =>
  adminApi.get(`/courses/${id}/sales-info`);
const getSingleFullExamFinanceInfo = (id: number) =>
  adminApi.get(`/full-exams/${id}/sales-info`);

const getWallet = ({
  queryName,
  queryValue,
}: {
  queryName: string;
  queryValue: string;
}) => adminApi.get(`/home?${queryName}=${queryValue}`);

const getOrders = (page: number) =>
  adminApi.get(`/orders?${page ? `page=${page}&` : ""}`);
const getOrdersForExport = () => adminApi.get(`/export-orders`);

// comments
const getComments = (courseId: number, page: number, limit: number) =>
  adminApi.get(
    `/comments/${courseId}?${page ? `page=${page}&` : ""}&${
      limit ? `limit=${limit}&` : ""
    }`
  );
const addComment = (data: any) => adminApi.post("/comments", data);
const updateComment = (commentId: number, data: any) =>
  adminApi.post(`/comments/${commentId}`, data);
const deleteComment = (commentId: number) =>
  adminApi.delete(`/comments/${commentId}`);

// get course students
const getCourseUsers = (courseId: number, page: number, search: string) => {
  return adminApi.get(
    `/courses/${courseId}/students?${page ? `page=${page}&` : ""}${
      search ? `search=${search}&` : ""
    }`
  );
};

const ADMINAPI = {
  getProfile,
  updateProfile,
  changePassword,
  getHome,
  getPermissions,
  getMyPermissions,
  getSubjects,
  getSingleSubject,
  createSubject,
  updateSubject,
  deleteSubject,
  getTeachers,
  getSingleTeacher,
  createTeacher,
  updateTeacher,
  deleteTeacher,
  updateTeachersOrder,
  getBooks,
  getSingleBook,
  createBook,
  updateBook,
  deleteBook,
  getExternalCodes,
  getExternalCode,
  createExternalCode,
  updateExternalCode,
  deleteExternalCode,
  getInternalCodes,
  getInternalCode,
  createInternalCode,
  updateInternalCode,
  deleteInternalCode,
  getModerators,
  getSingleModerator,
  createModerator,
  updateModerator,
  deleteModerator,
  getStudents,
  getSingleStudent,
  getStudentExams,
  correctQuestion,
  getUsers,
  getSingleUser,
  createUser,
  updateUser,
  updateUserStatus,
  deleteUser,
  adminLogin,
  getAllVideos,
  getTeacherVideos,
  getSingleVideo,
  createVideo,
  updateVideo,
  deleteVideo,
  getCourses,
  getSingleCourse,
  createCourse,
  updateCourse,
  deleteCourse,
  createCourseChapter,
  updateCourseChapter,
  deleteCourseChapter,
  createCourseLesson,
  deleteCourseLesson,
  updateLesson,
  updateLessonsOrder,
  getTeacherChapters,
  duplicateChapter,
  // Money
  getMoneyRequests,
  getMoneyRequest,
  createMoneyRequest,
  updateMoneyRequest,
  // Finances
  getFinances,
  getSingleBookSalesInfo,
  getSingleUserChargeWalletInfo,
  getSingleTeacherFinanceInfo,
  getSingleCourseFinanceInfo,
  getSingleFullExamFinanceInfo,
  // full exams
  getFullExams,
  getSingleFullExam,
  createFullExam,
  updateFullExam,
  deleteFullExam,
  createFullExamQuestion,
  deleteFullExamQuestion,

  updateFullExamQuestion,
  getSingleFullExamQuestion,
  // Home month chart
  getWallet,
  updateFullExamQuestionsOrder,

  getOrders,
  getOrdersForExport,
  // comments
  getComments,
  addComment,
  updateComment,
  deleteComment,
  getCourseUsers,
};

export default ADMINAPI;
