type TinitialValues = {
  allCountries: [];
  allCities: [];
  allAreas: [];
  languages: [];
  educationTypes: [];
  schoolYears: [];
};

export const initialValues: TinitialValues = {
  allCountries: [],
  allCities: [],
  allAreas: [],
  languages: [],
  educationTypes: [],
  schoolYears: [],
};
