import api from "../_axios";

const login = (data: any) => api.post("/login", data);
const getProfile = (data: any) => api.get("/profile");

const register = (data: any) => api.post("/register", data);
const regiterTeachers = (
  education_type_id: number,
  school_year_id: number,
  language_id: number,
) =>
  api.get(
    `/register-teachers?education_type_id=${education_type_id}&school_year_id=${school_year_id}&language_id=${language_id}`
  );
const verifyCode = (data: { mobile: string; code: string; type: string }) =>
  api.post("/verify-code", data);

const forgetPassword = (data: any) => api.post("/forget-password", data);
const changePassword = (data: any) => api.post("/change-password", data);
const resendCode = (data: any) => api.post("/resend-code", data);

const AUTHAPI = {
  login,
  getProfile,
  register,
  verifyCode,
  forgetPassword,
  changePassword,
  resendCode,
  regiterTeachers,
};

export default AUTHAPI;
