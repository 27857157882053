import { createSlice } from "@reduxjs/toolkit";
import { initialValue } from "./types";
import thunks from "./thunks";
import { RootState } from "../store";
import { toast } from "react-toastify";
import { deleteCookie, setCookie } from "cookies-next";
import { daysToMilliSecound } from "@/lib/utils";

const slice = createSlice({
  name: "admin",
  initialState: initialValue,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunks.doGetProfile.fulfilled, (state, action) => {
      state.profile = action.payload?.message;
    });
    builder.addCase(thunks.doGetProfile.rejected, (state, action: any) => {});
    builder.addCase(thunks.doUpdateProfile.fulfilled, (state, action) => {
      // toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doUpdateProfile.rejected, (state, action: any) => {
      // toast.error(action.payload.error);
    });
    builder.addCase(thunks.doChangePassword.fulfilled, (state, action) => {
      // toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doChangePassword.rejected, (state, action: any) => {
      // toast.error(action.payload?.message);
    });
    builder.addCase(thunks.doGetSubjects.fulfilled, (state, action) => {
      state.subjects = action.payload.data;
    });
    builder.addCase(thunks.doGetSubjects.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetHome.fulfilled, (state, action) => {
      state.home = action.payload.data;
    });
    builder.addCase(thunks.doGetHome.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetPermissions.fulfilled, (state, action) => {
      state.permissions = action.payload.data;
    });
    builder.addCase(
      thunks.doGetPermissions.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doGetMyPermissions.fulfilled, (state, action) => {
      state.myPermissions = action.payload.data;
    });
    builder.addCase(
      thunks.doGetMyPermissions.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doGetSingleSubject.fulfilled, (state, action) => {
      state.singleSubject = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleSubject.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doCreateSubject.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doCreateSubject.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doUpdateSubject.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doUpdateSubject.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doDeleteSubject.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doDeleteSubject.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });

    builder.addCase(thunks.doGetTeachers.fulfilled, (state, action) => {
      state.teachers = action.payload.data;
    });
    builder.addCase(thunks.doGetTeachers.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetSingleTeacher.fulfilled, (state, action) => {
      state.singleTeacher = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleTeacher.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doCreateTeacher.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doCreateTeacher.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doUpdateTeacher.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doUpdateTeacher.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doDeleteTeacher.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doDeleteTeacher.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doUpdateTeachersOrder.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(
      thunks.doUpdateTeachersOrder.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );

    builder.addCase(thunks.doGetBooks.fulfilled, (state, action) => {
      state.books = action.payload.data;
    });
    builder.addCase(thunks.doGetBooks.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetSingleBook.fulfilled, (state, action) => {
      state.singleBook = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleBook.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doCreateBook.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doCreateBook.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doUpdateBook.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doUpdateBook.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doDeleteBook.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doDeleteBook.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });

    builder.addCase(thunks.doGetExternalCodes.fulfilled, (state, action) => {
      state.externalCodes = action.payload.data;
    });
    builder.addCase(
      thunks.doGetExternalCodes.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doGetExternalCode.fulfilled, (state, action) => {
      state.singleExternalCode = action.payload.data;
    });
    builder.addCase(
      thunks.doGetExternalCode.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doCreateExternalCode.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(
      thunks.doCreateExternalCode.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );
    builder.addCase(thunks.doUpdateExternalCode.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(
      thunks.doUpdateExternalCode.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );
    builder.addCase(thunks.doDeleteExternalCode.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(
      thunks.doDeleteExternalCode.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );

    builder.addCase(thunks.doGetInternalCodes.fulfilled, (state, action) => {
      state.internalCodes = action.payload.data;
    });
    builder.addCase(
      thunks.doGetInternalCodes.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doGetInternalCode.fulfilled, (state, action) => {
      state.singleInternalCode = action.payload.data;
    });
    builder.addCase(
      thunks.doGetInternalCode.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doCreateInternalCode.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(
      thunks.doCreateInternalCode.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );
    builder.addCase(thunks.doUpdateInternalCode.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(
      thunks.doUpdateInternalCode.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );
    builder.addCase(thunks.doDeleteInternalCode.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(
      thunks.doDeleteInternalCode.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );

    builder.addCase(thunks.doGetModerators.fulfilled, (state, action) => {
      state.moderators = action.payload.data;
    });
    builder.addCase(
      thunks.doGetModerators.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doGetSingleModerator.fulfilled, (state, action) => {
      state.singleModerator = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleModerator.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doCreateModerator.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doCreateModerator.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doUpdateModerator.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doUpdateModerator.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doDeleteModerator.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doDeleteModerator.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });

    builder.addCase(thunks.doGetStudents.fulfilled, (state, action) => {
      state.students = action.payload.data;
    });
    builder.addCase(thunks.doGetStudents.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetSingleStudent.fulfilled, (state, action) => {
      state.singleStudent = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleStudent.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doGetStudentExams.fulfilled, (state, action) => {
      state.studentExams = action.payload.data;
    });
    builder.addCase(
      thunks.doGetStudentExams.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doCorrectQuestion.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doCorrectQuestion.rejected, (state, action: any) => {
      toast.error(
        action.payload && action.payload.error
          ? action.payload.error
          : action.payload?.message
      );
    });
    builder.addCase(thunks.doGetUsers.fulfilled, (state, action) => {
      state.users = action.payload.data;
    });
    builder.addCase(thunks.doGetUsers.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetSingleuser.fulfilled, (state, action) => {
      state.singleUser = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleuser.rejected,
      (state, action: any) => {}
    );

    builder.addCase(thunks.doCreateUser.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doCreateUser.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doUpdateUserStatus.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(
      thunks.doUpdateUserStatus.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );
    builder.addCase(thunks.doUpdateUser.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doUpdateUser.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doDeleteUser.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doDeleteUser.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });

    // login
    builder.addCase(thunks.doAdminLogin.fulfilled, (state, action) => {
      deleteCookie("Education_token");
      deleteCookie("teacher__teacher");
      deleteCookie("Education_User_token");
      deleteCookie("seveezToken");
      localStorage.removeItem("isLogged");
      localStorage.removeItem("username");
      localStorage.removeItem("image");

      toast.success(action.payload?.message);

      setCookie(
        "Education_token",
        action.payload.data.token
          ? action.payload.data.token
          : action.payload.data.user.token,
        { maxAge: daysToMilliSecound(1000) }
      );

      if (action.payload.data.role === 0) {
        state.adminLogged.role = "admin";
      } else if (action.payload.data.role === 2) {
        setCookie("teacher__teacher", "teacher__teacher", {
          maxAge: daysToMilliSecound(1000),
        });
        state.adminLogged.role = "teacher";
      } else if (action.payload.data.user.role === 3) {
        /* moderator for platform */
        setCookie("platform__moderator", "platform__moderator");
        state.adminLogged.role = "moderator";
      } else if (action.payload.data.user.role === 4) {
        /* moderator for teacher */
        setCookie("teacher__moderator", "teacher__moderator");
        state.adminLogged.role = "moderator";
      }

      state.adminLogged.adminToken = action.payload.data.token;
      state.adminLogged.isLoggedIn = true;
      state.adminLogged.adminData = action.payload.data;
      localStorage.setItem(
        "username",
        action.payload.data.name
          ? action.payload.data.name
          : action.payload.data.user.name
      );
      localStorage.setItem(
        "image",
        action.payload.data.image
          ? action.payload.data.image
          : action.payload.data.user.image
      );
    });
    builder.addCase(thunks.doAdminLogin.rejected, (state, action: any) => {
      deleteCookie("Education_token");
      deleteCookie("teacher__teacher");
      deleteCookie("Education_User_token");
      deleteCookie("seveezToken");
      localStorage.removeItem("isLogged");
      localStorage.removeItem("username");
      localStorage.removeItem("image");

      toast.error(action.payload?.message);
      state.adminLogged.role = "";
      state.adminLogged.isLoggedIn = false;
      state.adminLogged.adminToken = null;
      state.adminLogged.adminData = {};
      localStorage.removeItem("username");
      localStorage.removeItem("image");
    });
    builder.addCase(thunks.doGetAllVideos.fulfilled, (state, action) => {
      state.videos = action.payload.data;
    });
    builder.addCase(thunks.doGetAllVideos.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetTeacherVideos.fulfilled, (state, action) => {
      state.teacherVideos = action.payload.data;
    });
    builder.addCase(
      thunks.doGetTeacherVideos.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doGetSingleVideo.fulfilled, (state, action) => {
      state.singleVideo = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleVideo.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doCreateVideo.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doCreateVideo.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doUpdateVideo.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doUpdateVideo.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doDeleteVideo.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doDeleteVideo.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    // courses
    builder.addCase(thunks.doGetCourses.fulfilled, (state, action) => {
      state.courses = action.payload.data;
    });
    builder.addCase(thunks.doGetCourses.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetSingleCourse.fulfilled, (state, action) => {
      state.singleCourse = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleCourse.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doCreateCourse.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doCreateCourse.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doUpdateCourse.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doUpdateCourse.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doDeleteCourse.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doDeleteCourse.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    // courses - chapters
    builder.addCase(thunks.doCreateCourseChapter.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(
      thunks.doCreateCourseChapter.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );
    builder.addCase(thunks.doUpdateCourseChapter.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(
      thunks.doUpdateCourseChapter.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );
    builder.addCase(thunks.doDeleteCourseChapter.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(
      thunks.doDeleteCourseChapter.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );
    // courses - chapters - lessons
    builder.addCase(thunks.doCreateCourseLesson.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(
      thunks.doCreateCourseLesson.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );
    // courses - chapters - lessons
    builder.addCase(thunks.doDeleteCourseLesson.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(
      thunks.doDeleteCourseLesson.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );
    // Money
    builder.addCase(thunks.doGetMoneyRequests.fulfilled, (state, action) => {
      state.moneyRequests = action.payload.data;
    });
    builder.addCase(
      thunks.doGetMoneyRequests.rejected,
      (state, action: any) => {}
    );

    builder.addCase(thunks.doGetMoneyRequest.fulfilled, (state, action) => {
      state.moneyRequest = action.payload.data;
    });
    builder.addCase(
      thunks.doGetMoneyRequest.rejected,
      (state, action: any) => {}
    );

    builder.addCase(thunks.doCreateMoneyRequest.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });

    builder.addCase(
      thunks.doCreateMoneyRequest.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );

    builder.addCase(thunks.doUpdateMoneyRequest.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(
      thunks.doUpdateMoneyRequest.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );

    // Finances
    builder.addCase(thunks.doGetFinances.fulfilled, (state, action) => {
      state.finances = action.payload.data;
    });
    builder.addCase(thunks.doGetFinances.rejected, (state, action: any) => {});

    builder.addCase(
      thunks.doGetSingleBookSalesInfo.fulfilled,
      (state, action) => {
        state.singleBookSalesInfo = action.payload.data;
      }
    );
    builder.addCase(
      thunks.doGetSingleBookSalesInfo.rejected,
      (state, action: any) => {}
    );
    builder.addCase(
      thunks.doGetSingleTeacherFinanceInfo.fulfilled,
      (state, action) => {
        state.singleTeacherFinanceInfo = action.payload.data;
      }
    );
    builder.addCase(
      thunks.doGetSingleTeacherFinanceInfo.rejected,
      (state, action: any) => {}
    );

    builder.addCase(
      thunks.doGetSingleUserChargeWalletInfo.fulfilled,
      (state, action) => {
        state.singleUserChargeWalletInfo = action.payload.data;
      }
    );
    builder.addCase(
      thunks.doGetSingleUserChargeWalletInfo.rejected,
      (state, action: any) => {}
    );

    builder.addCase(
      thunks.doGetSingleCourseFinanceInfo.fulfilled,
      (state, action) => {
        state.singleCourseFinanceInfo = action.payload.data;
      }
    );
    builder.addCase(
      thunks.doGetSingleCourseFinanceInfo.rejected,
      (state, action: any) => {}
    );

    builder.addCase(
      thunks.doGetSingleFullExamFinanceInfo.fulfilled,
      (state, action) => {
        state.singleFullExamFinanceInfo = action.payload.data;
      }
    );
    builder.addCase(
      thunks.doGetSingleFullExamFinanceInfo.rejected,
      (state, action: any) => {}
    );

    builder.addCase(thunks.doUpdateLesson.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });

    builder.addCase(thunks.doUpdateLesson.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });

    builder.addCase(thunks.doUpdateLessonsOrder.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });

    builder.addCase(
      thunks.doUpdateLessonsOrder.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );

    builder.addCase(thunks.doGetTeacherChapters.fulfilled, (state, action) => {
      state.teacherChapters = action.payload.data;
    });

    builder.addCase(
      thunks.doGetTeacherChapters.rejected,
      (state, action: any) => {
        toast.error(action.payload?.error);
      }
    );

    builder.addCase(thunks.doDuplicateChapter.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });

    builder.addCase(
      thunks.doDuplicateChapter.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );
    // full exam
    builder.addCase(thunks.doCreateFullExam.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doCreateFullExam.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doUpdateFullExam.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doUpdateFullExam.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doGetFullExams.fulfilled, (state, action) => {
      state.fullExams = action.payload.data;
      // toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doGetFullExams.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doGetSingleFullExam.fulfilled, (state, action) => {
      state.singleFullExam = action.payload.data;
      // toast.success(action.payload?.message);
    });
    builder.addCase(
      thunks.doGetSingleFullExam.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );
    builder.addCase(thunks.doDeleteFullExam.fulfilled, (state, action) => {
      toast.success(action.payload?.message);
    });
    builder.addCase(thunks.doDeleteFullExam.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(
      thunks.doCreateFullExamQuestion.fulfilled,
      (state, action) => {
        toast.success(action.payload?.message);
      }
    );
    builder.addCase(
      thunks.doCreateFullExamQuestion.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );
    builder.addCase(
      thunks.doDeleteFullExamQuestion.fulfilled,
      (state, action) => {
        toast.success(action.payload?.message);
      }
    );
    builder.addCase(
      thunks.doDeleteFullExamQuestion.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );
    // doGetWallet
    builder.addCase(thunks.doGetWallet.fulfilled, (state, action) => {
      state.chartByMonth = action.payload.data;
    });
    builder.addCase(thunks.doGetWallet.rejected, (state, action: any) => {});
    builder.addCase(
      thunks.doUpdateFullExamQuestionsOrder.fulfilled,
      (state, action) => {
        toast.success(action.payload?.message);
      }
    );
    builder.addCase(
      thunks.doUpdateFullExamQuestionsOrder.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );

    builder.addCase(
      thunks.doUpdateFullExamQuestion.fulfilled,
      (state, action) => {
        toast.success(action.payload?.message);
      }
    );
    builder.addCase(
      thunks.doUpdateFullExamQuestion.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );

    builder.addCase(
      thunks.doGetSingleFullExamQuestion.fulfilled,
      (state, action) => {
        state.singleFullExamQuestion = action.payload.data;
        // toast.success(action.payload?.message);
      }
    );
    builder.addCase(
      thunks.doGetSingleFullExamQuestion.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );
    builder.addCase(thunks.doGetOrders.fulfilled, (state, action) => {
      state.orders = action.payload.data;
    });
    builder.addCase(thunks.doGetOrders.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(
      thunks.doGetOrdersForExport.fulfilled,
      (state, action) => {}
    );
    builder.addCase(
      thunks.doGetOrdersForExport.rejected,
      (state, action: any) => {
        toast.error(action.payload.error);
      }
    );
    builder.addCase(thunks.doGetComments.fulfilled, (state, action) => {
      state.comments = action.payload.data;
    });
    builder.addCase(thunks.doGetComments.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doAddComment.fulfilled, (state, action) => {});
    builder.addCase(thunks.doAddComment.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doUpdateComment.fulfilled, (state, action) => {});
    builder.addCase(thunks.doUpdateComment.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doDeleteComment.fulfilled, (state, action) => {});
    builder.addCase(thunks.doDeleteComment.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
    builder.addCase(thunks.doGetCourseUsers.fulfilled, (state, action) => {
      state.courseUsers = action.payload.data;
    });
    builder.addCase(thunks.doGetCourseUsers.rejected, (state, action: any) => {
      toast.error(action.payload.error);
    });
  },
});

// export const SelectAdminData = (state: RootState) => state.admin.adminLogged;

export const SelectProfile = (state: RootState) => state.user.profile;
export const SelectPermissions = (state: RootState) => state.admin.permissions;
export const SelectMyPermissions = (state: RootState) =>
  state.admin.myPermissions;
export const SelectHome = (state: RootState) => state.admin.home;

export const SelectSubjects = (state: RootState) => state.admin.subjects;
export const SelectSingleSubject = (state: RootState) =>
  state.admin.singleSubject;

export const SelectTeachers = (state: RootState) => state.admin.teachers;
export const SelectSingleTeacher = (state: RootState) =>
  state.admin.singleTeacher;

export const SelectBooks = (state: RootState) => state.admin.books;
export const SelectSingleBook = (state: RootState) => state.admin.singleBook;

export const SelectExternalCodes = (state: RootState) =>
  state.admin.externalCodes;
export const SelectSingleExternalCode = (state: RootState) =>
  state.admin.singleExternalCode;
export const SelectInternalCodes = (state: RootState) =>
  state.admin.internalCodes;
export const SelectSingleInternalCode = (state: RootState) =>
  state.admin.singleInternalCode;

export const SelectModerators = (state: RootState) => state.admin.moderators;
export const SelectSingleModerator = (state: RootState) =>
  state.admin.singleModerator;

export const SelectStudents = (state: RootState) => state.admin.students;
export const SelectSingleStudent = (state: RootState) =>
  state.admin.singleStudent;
export const SelectStudentExams = (state: RootState) =>
  state.admin.studentExams;

export const SelectUsers = (state: RootState) => state.admin.users;
export const SelectSingleUser = (state: RootState) => state.admin.singleUser;

export const SelectVideos = (state: RootState) => state.admin.videos;
export const SelectTeacherVideos = (state: RootState) =>
  state.admin.teacherVideos;
export const SelectSingleVideo = (state: RootState) => state.admin.singleVideo;
export const SelectCourses = (state: RootState) => state.admin.courses;
export const SelectSingleCourse = (state: RootState) =>
  state.admin.singleCourse;
export const SelectTeacherChapters = (state: RootState) =>
  state.admin.teacherChapters;
// full exams
export const SelectFullExams = (state: RootState) => state.admin.fullExams;
export const SelectSingleExam = (state: RootState) =>
  state.admin.singleFullExam;

// Money
export const SelectMoneyRequests = (state: RootState) =>
  state.admin.moneyRequests;
export const SelectMoneyRequest = (state: RootState) =>
  state.admin.moneyRequest;

// Finances
export const SelectFinances = (state: RootState) => state.admin.finances;
export const SelectSingleBookSalesInfo = (state: RootState) =>
  state.admin.singleBookSalesInfo;
export const SelectSingleTeacherFinanceInfo = (state: RootState) =>
  state.admin.singleTeacherFinanceInfo;
export const SelectSingleUserChargeWalletInfo = (state: RootState) =>
  state.admin.singleUserChargeWalletInfo;
export const SelectSingleCourseFinanceInfo = (state: RootState) =>
  state.admin.singleCourseFinanceInfo;
export const SelectSingleFullExamFinanceInfo = (state: RootState) =>
  state.admin.singleFullExamFinanceInfo;

// ChartByMonth
export const SelectWallet = (state: RootState) => state.user.chartByMonth;

export const SelectSingleFullExamQuestion = (state: RootState) =>
  state.admin.singleFullExamQuestion;

export const SelectOrders = (state: RootState) => state.admin.orders;
export const SelectCommentsForCourse = (state: RootState) =>
  state.admin.comments;

export const SelectCourseUsers = (state: RootState) => state.admin.courseUsers;

const ADMIN = {
  slice,
  thunks,
};

export default ADMIN;
