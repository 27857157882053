import { createAsyncThunk } from "@reduxjs/toolkit";
import GENERALAPI from "./api";

const doGetAllCountries = createAsyncThunk<any, any, any>(
  "general/doGetAllCountries",
  async ({}, { rejectWithValue }) => {
    try {
      const response = GENERALAPI.getCountries();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doGetALLCITIES = createAsyncThunk<any, any, any>(
  "general/getAllCities",
  async ({}, { rejectWithValue }) => {
    try {
      const response = GENERALAPI.getCities();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doGetAllAreas = createAsyncThunk<any, any, any>(
  "general/getAllAreas",
  async ({ cityid }, { rejectWithValue }) => {
    try {
      const response = GENERALAPI.getAreas(cityid);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const doGetLanguages = createAsyncThunk<any, any, any>(
  "general/getLanguages",
  async ({}, { rejectWithValue }) => {
    try {
      const response = GENERALAPI.getLanguages();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const doGetEducationTypes = createAsyncThunk<any, any, any>(
  "general/getEducationTypes",
  async ({ school_year_id }, { rejectWithValue }) => {
    try {
      const response = GENERALAPI.getEducationTypes(school_year_id);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const doGetSchoolYears = createAsyncThunk<any, any, any>(
  "general/getSchoolYears",
  async ({ teacherId }, { rejectWithValue }) => {
    try {
      const response = GENERALAPI.getSchoolYears(teacherId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const thunks = {
  doGetAllCountries,
  doGetALLCITIES,
  doGetAllAreas,
  doGetLanguages,
  doGetEducationTypes,
  doGetSchoolYears,
};

export default thunks;
