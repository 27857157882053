import api from "../_axios";

const getCountries = () => api.get("https://backend.x-hubeg.com/api/v1/countries");
const getCities = () => api.get("https://backend.x-hubeg.com/api/v1/cities");
const getAreas = (cityid: number) =>
  api.get(`https://backend.x-hubeg.com/api/v1/areas/${cityid}`);
const getLanguages = () => api.get("https://backend.x-hubeg.com/api/v1/languages");
const getEducationTypes = (school_year_id: any) =>
  api.get(
    `https://backend.x-hubeg.com/api/v1/education-types?school_year_id=${school_year_id}`
  );
const getSchoolYears = (teacherId?: number) =>
  api.get(
    `https://backend.x-hubeg.com/api/v1/school-years?teacher_id=${
      teacherId ? teacherId : ""
    }`
  );

const GENERALAPI = {
  getCountries,
  getCities,
  getAreas,
  getLanguages,
  getEducationTypes,
  getSchoolYears,
};
export default GENERALAPI;
